import {
  handleError,
  handleSuccess,
} from '../../helpers/toastHelpers/toastHandlers'
import toastTypes from '../../helpers/toastHelpers/toastTypes'
import * as types from '../constants'

const { errorTypes, successTypes } = toastTypes

const toastMiddleware = () => (next) => (action) => {
  switch (action.type) {
    // #region users.js
    case types.CREATE_CLIENT_SUCCESS:
      handleSuccess(successTypes.CREATE_CLIENT_SUCCESS)
      return next(action)

    case types.CREATE_CLIENT_ERROR:
      handleError(errorTypes.CREATE_CLIENT_ERROR)
      return next(action)
    case types.CREATE_USER_ERROR:
      handleError(errorTypes.CREATE_USER_ERROR)
      return next(action)

    case types.UPDATE_USER_SUCCESS:
      if (action.payload.user.imageValid === false) {
        handleError(errorTypes.UPDATE_USER_SUCCESS_IMAGE_NOT_VALID)
      } else {
        handleSuccess(successTypes.UPDATE_USER_SUCCESS)
      }
      return next(action)

    case types.UPDATE_USER_ERROR:
      if (
        action.payload?.response?.message?.errmsg?.includes('cif_1 dup key:') ||
        action.payload?.response?.message?.keyValue?.cif
      ) {
        handleError(errorTypes.UPDATE_USER_ERROR_CIF_USED)
      } else {
        handleError(
          action.payload.response?.message || errorTypes.UPDATE_USER_ERROR
        )
      }
      return next(action)

    case types.DELETE_USER_SUCCESS:
      handleSuccess(successTypes.DELETE_USER_SUCCESS)
      return next(action)

    case types.DELETE_USER_ERROR:
      handleError(
        action.payload.response.message
          ? action.payload.response.message
          : errorTypes.DELETE_USER_ERROR
      )
      return next(action)

    case types.CREATE_USER_SUCCESS:
      handleSuccess(successTypes.CREATE_USER_SUCCESS)
      return next(action)

    case types.ADD_SUBSTITUTE_EXCLUDED_ERROR:
      handleError(errorTypes.ADD_SUBSTITUTE_EXCLUDED_ERROR)
      return next(action)

    case types.ADD_SUBSTITUTE_EXCLUDED_SUCCESS:
      handleSuccess(successTypes.ADD_SUBSTITUTE_EXCLUDED_SUCCESS)
      return next(action)

    case types.REMOVE_SUBSTITUTE_EXCLUDED_ERROR:
      handleError(errorTypes.REMOVE_SUBSTITUTE_EXCLUDED_ERROR)
      return next(action)

    case types.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS:
      handleSuccess(successTypes.REMOVE_SUBSTITUTE_EXCLUDED_SUCCESS)
      return next(action)

    case types.SET_LINKED_PARTNERS_ERROR:
      handleError(errorTypes.SET_LINKED_PARTNERS_ERROR)
      return next(action)

    case types.DELETE_LINKED_PARTNER_ERROR:
      handleError(errorTypes.DELETE_LINKED_PARTNER_ERROR)
      return next(action)

    case types.ADD_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400) {
        handleError(errorTypes.ADD_CUSTOM_FIELD_ERROR)
      }
      return next(action)

    case types.UPDATE_CUSTOM_FIELD_ERROR:
      if (action.payload.status === 400) {
        handleError(errorTypes.UPDATE_CUSTOM_FIELD_ERROR)
      }
      return next(action)

    case types.CHANGE_PARTNER_TO_COMPANY_SUCCESS:
      handleSuccess(successTypes.CHANGE_PARTNER_TO_COMPANY_SUCCESS)
      return next(action)

    case types.CHANGE_PARTNER_TO_COMPANY_ERROR:
      handleError(errorTypes.CHANGE_PARTNER_TO_COMPANY_ERROR)
      return next(action)

    case types.ADD_AUTH_POOL_SUCCESS:
    case types.EDIT_AUTH_POOL_SUCCESS:
      handleSuccess(successTypes.ADD_AUTH_POOL_SUCCESS)
      return next(action)

    case types.ADD_AUTH_POOL_ERROR:
    case types.EDIT_AUTH_POOL_ERROR:
      if (action.payload.status === 409) {
        handleError(errorTypes.ADD_AUTH_POOL_ERROR)
      } else {
        handleError(errorTypes.EDIT_AUTH_POOL_ERROR)
      }
      return next(action)
    case types.ADD_FAVORITE_SUBSTITUTE_SUCCESS:
      handleSuccess(successTypes.ADD_FAVORITE_SUBSTITUTE_SUCCESS)
      return next(action)
    case types.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS:
      handleSuccess(successTypes.REMOVE_FAVORITE_SUBSTITUTE_SUCCESS)
      return next(action)

    // #region pigeonAI.js
    case types.PIGEONAI_UPLOAD_FILES_SUCCESS:
      handleSuccess('Archivos subidos correctamente')
      return next(action)

    case types.PIGEONAI_UPLOAD_FILES_ERROR:
      handleError(errorTypes.PIGEONAI_UPLOAD_FILES_ERROR)
      return next(action)

    case types.PIGEONAI_DOWNLOAD_FILE_ERROR:
      handleError(errorTypes.PIGEONAI_DOWNLOAD_FILE_ERROR)
      return next(action)

    case types.PIGEONAI_GET_STATUS_ERROR:
      if (action.payload.response.instructa.status === 'canceled') {
        handleError(errorTypes.PIGEONAI_INSTRUCTA_CANCELED)
      } else {
        handleError(errorTypes.PIGEONAI_GET_STATUS_ERROR)
      }
      return next(action)

    default:
      return next(action)
  }
}

export default toastMiddleware
